import React from 'react'
import Helmet from 'react-helmet'
import Breadcrumbs from '../components/breadcrumbs'
import Hero from '../components/hero'
import DocumentiUtili from '../components/documenti-utili'
import Layout from "../components/layout"
import { graphql } from "gatsby"
import NavMenu from '../components/nav-menu'


class AreaStudenti extends React.Component {
  
  render() { 
    const breadcrumb = [{"title":"Area studenti"}];
    const pagina = this.props.data.contentfulContenutoGenerico;
    const hero = this.props.data.hero;
    const datahero = {
      "title": "Centro Zootecnico Didattico Sperimentale",
      "subtitle": "Area studenti",
      "arealink": false 
    };
    const pagineFiglie = [{titolo: "Descrizione", friendlyUrl: pagina.friendlyUrl}].concat(pagina.pagineFiglie);


    return (
      <Layout location={this.props.location}>
        <Helmet
           title="Area studenti"
           bodyAttributes={
            { class :'has-hero'}
          }
        />
        <Hero data={datahero} cover={hero.childImageSharp}/>

        <div className="container">
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
              {!!pagina.testo && 
                <div dangerouslySetInnerHTML={{ __html: pagina.testo.childMarkdownRemark.html }} >
                
                </div>
              }
            </div>
            <div className="col-md-4">
              {!!pagineFiglie &&
                <NavMenu section="Area Studenti" active={pagina.friendlyUrl} data={pagineFiglie}/>
              }
              {!!pagina.titoloSpallaDestra &&
                <div className="webcontent">
                  <h2 className="webcontent__title">{pagina.titoloSpallaDestra}</h2>
                    <div className="webcontent__abstract" dangerouslySetInnerHTML={{ __html: pagina.testoSpallaDestra.childMarkdownRemark.html }} >
                    
                    </div>
                </div>
              }
              {!!pagina.attachment &&
                <DocumentiUtili section="Documenti Utili" data={pagina.attachment}/>
              }
            </div>
          </div>
        </div>

  

           

      </Layout>
    )
  }

}

export default AreaStudenti;

export const query = graphql`
query AreaStudentiQuery{
  contentfulContenutoGenerico (contentful_id: {eq: "57u5uCzjvzykpaK6xBPVym"}) {
    titolo
    friendlyUrl
    testo {
      childMarkdownRemark {
        html
      }
    }
    attachment {
      title
      file {
        url
      }
    }
    pagineFiglie {
      contentful_id
      friendlyUrl
      titolo
    }
    titoloSpallaDestra
    testoSpallaDestra {
      childMarkdownRemark {
        html
      }
    }
  }
  hero: file(relativePath: {eq: "img_area-studenti.png"}) {
    childImageSharp{
      fluid(maxWidth: 1920){
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`